window.jQuery = require("jquery");
var $ = window.jQuery;
var debounce = require("lodash/debounce");
var stickybits = require("stickybits");
var scrollMonitor = require("scrollmonitor");
var createFocusTrap = require("focus-trap");
var Cookies = require("js-cookie");
window.scrollMonitor = scrollMonitor;

var stateDotsSticky = stickybits(".dots-will-stick", {
  useStickyClasses: true
});

var focusTrapOptions = {
  escapeDeactivates: false,
  onActivate: function() {
    if ($(".modal.active").hasClass("state-info-modal")) {
      $('[data-toggle-view="true"]')
        .not(".fixed-bar-wrap")
        .attr("aria-hidden", "true");
    } else {
      $('[data-toggle-view="true"]').attr("aria-hidden", "true");
    }
  },
  onDeactivate: function() {
    $('[data-toggle-view="true"]').attr("aria-hidden", "false");
  }
};

var statesNavFocusTrap = createFocusTrap(".states-nav", focusTrapOptions);
var takeActionFocusTrap = createFocusTrap(
  ".take-action-modal",
  focusTrapOptions
);
var racialDisparitiesFocusTrap = createFocusTrap(
  ".racial-disparities-modal",
  focusTrapOptions
);
var stateInfoFocusTrap = createFocusTrap(".state-info-modal", focusTrapOptions);

$(document).ready(function() {
  if ($(".state-details").length) {
    initStatePage();
  }

  initNav();
  initDots();
  initScrollPosition();
  initForms();

  // setup focus class
  var allowTabFocus = false;

  $(window).on("keydown", function(e) {
    $("*").removeClass("tab-focus");
    if (e.keyCode === 9) {
      allowTabFocus = true;
    }
  });

  $("*").on("focus", function() {
    if (allowTabFocus) {
      var target = $(this);
      setTimeout(function() {
        target.addClass("tab-focus");
      }, 25);
    }
  });

  $(window).on("mousedown", function() {
    $("*").removeClass("tab-focus");
    allowTabFocus = false;
  });

  setTimeout(function() {
    if ($("body").hasClass("home")) {
      $(".loader-dots").addClass("wide");
      setTimeout(function() {
        $("body")
          .removeClass("loading")
          .addClass("loaded");
        setTimeout(function() {
          $(".loading-indicator").remove();
        }, 300);
      }, 2750);
    } else {
      $("body")
        .removeClass("loading")
        .addClass("loaded");

      setTimeout(function() {
        $(".loading-indicator").remove();
      }, 300);
    }

    $(".tip").each(function() {
      var thisTip = $(this);
      var tipMonitor = scrollMonitor.create(thisTip[0]);
      tipMonitor.enterViewport(function() {
        thisTip.addClass("in-viewport");
      });
      // tipMonitor.exitViewport(function() {
      //   thisTip.removeClass('in-viewport');
      // });
    });
  }, 500);

  $(window)
    .on("scroll", function() {
      if ($(window).scrollTop() > 0) {
        $("body").addClass("has-scrolled");
      } else {
        $("body").removeClass("has-scrolled");
      }
    })
    .trigger("scroll");
});

var initNav = function() {
  $(".modal [tabindex]").attr("tabindex", "-1");

  $(".section--tip [tabindex]").attr("tabindex", "-1");

  $(".toggle-states-nav").on("touchstart click", function(e) {
    e.preventDefault();
    $("body").toggleClass("show-states-nav");

    $("body").addClass("show-modal");

    if ($("body").hasClass("show-states-nav")) {
      $(".states-nav.modal").addClass("active");
      $(".states-nav.modal [tabindex]").attr("tabindex", "1");
      $(".states-nav.modal").attr("aria-hidden", "false");
      statesNavFocusTrap.activate();
    } else {
      $(".states-nav.modal").removeClass("active");
      statesNavFocusTrap.deactivate();
      $(".states-nav.modal [tabindex]").attr("tabindex", "-1");
      $(".states-nav.modal").attr("aria-hidden", "false");
      $("body").removeClass("show-modal");
    }
  });

  $(".racial-disparities-link, .racial-disparities-toggle").on(
    "touchstart click",
    function(e) {
      e.preventDefault();
      $(".racial-disparities-modal.modal [tabindex]").attr("tabindex", "1");
      $(".racial-disparities-modal.modal").attr("aria-hidden", "false");
      $(".racial-disparities-modal").addClass("active");
      $("body").addClass("show-modal");
      racialDisparitiesFocusTrap.activate();
    }
  );

  $(".state-info-modal-link").on("touchstart click", function(e) {
    e.preventDefault();
    $(".state-info-modal.modal [tabindex]").attr("tabindex", "1");
    $(".state-info-modal.modal").attr("aria-hidden", "false");
    $(".state-info-modal").addClass("active");
    $("body").addClass("show-modal");
    stateInfoFocusTrap.activate();
  });

  $(".take-action-modal-link").on("touchstart click", function(e) {
    e.preventDefault();

    $(".take-action-modal.modal [tabindex]").attr("tabindex", "1");
    $(".take-action-modal.modal").attr("aria-hidden", "false");
    $(".take-action-modal").addClass("active");
    $("body").addClass("show-modal");
    takeActionFocusTrap.activate();
  });

  $(".modal .close-link").on("touchstart click", function(e) {
    e.preventDefault();
    $(this)
      .parents(".modal")
      .attr("aria-hidden", "true")
      .removeClass("active");

    $(this)
      .parents(".modal")
      .find("[tabindex]")
      .attr("tabindex", "-1");
    $("body").removeClass("show-modal show-states-nav");
    statesNavFocusTrap.deactivate();
    takeActionFocusTrap.deactivate();
    racialDisparitiesFocusTrap.deactivate();
    stateInfoFocusTrap.deactivate();
  });

  $(window)
    .on("resize", function() {
      $(".reforms .sticky-parent").css({
        width: $(".reforms").width()
      });
      scrollMonitor.update();
      stateDotsSticky.update();
    })
    .trigger("resize");
};

var initDots = function() {
  if ($(".dots").length) {
    $(window)
      .on("resize", debounce(updateDots, 150))
      .trigger("resize");
  }
};

var initScrollPosition = function() {
  if ($(".scroll-position").length) {
    $(window)
      .on("scroll", updateScrollPosition)
      .trigger("scroll");
  }
};

var updateDots = function() {
  var dotWidth = 5,
    dotCount = $(".dots").attr("data-dot-count"),
    dotContainerFullWidth = "100%",
    dotContainerFullHeight = "100%",
    w = window.innerWidth,
    h = window.innerHeight,
    dotContainer = document.getElementsByClassName("dots")[0],
    remainderDiv = document.getElementsByClassName("cover")[0];

  if ($(window).width() <= 767) {
    dotWidth = 2;
  }

  if ($(".dots").parents(".state-details").length && $(window).width() >= 768) {
    w = window.innerWidth / 2;
  }

  // state details page, add dots hidden by logo
  if ($(".dots").parents(".state-details").length) {
    if ($(window).width() >= 768) {
      var logoWidth = $(".site-logo").width(),
        logoHeight = $(".site-logo").height(),
        logoCols = Math.floor(logoWidth / dotWidth),
        logoRows = Math.floor(logoHeight / dotWidth),
        logoDots = logoCols * logoRows;

      dotCount = parseInt(dotCount) + parseInt(logoDots);
    }

    // add estimated dots hidden behind each tip
    $(".tip").each(function() {
      var tipWidth = $(this).outerWidth(),
        tipHeight = $(this).outerHeight(),
        tipCols = Math.floor(tipWidth / dotWidth),
        tipRows = Math.floor(tipHeight / dotWidth),
        tipDots = tipCols * tipRows;

      dotCount = parseInt(dotCount) + parseInt(tipDots);
    });
  }

  // determine how many dots can go horizontal
  var col = Math.floor(w / dotWidth);

  // start increasing width
  var numDots = dotCount;
  if (numDots <= col) {
    dotContainer.style.width = numDots * dotWidth + "px";
    dotContainer.style.height = dotWidth + "px";
    remainderDiv.style.width = 0;
  } else {
    // determine col #
    var totalRows = Math.ceil(numDots / col);
    dotContainer.style.width = dotContainerFullWidth;
    dotContainer.style.height = totalRows * dotWidth + "px";

    var remainder = numDots % col;
    remainderDiv.style.width = w - remainder * dotWidth + "px";
  }

  if ($(".reforms .sticky-parent").length && $(window).width() >= 768) {
    $(".reforms .sticky-parent").css({
      minHeight: totalRows * dotWidth + "px"
    });
  } else if ($(".reforms .sticky-parent").length) {
    $(".reforms .sticky-parent").css("minHeight", "");
  }

  if ($(".error-404").length) {
    $(".section-wrap").height(
      $(".section-wrap")
        .find(".section--text")
        .outerHeight() + 100
    );
  }

  // check for active reforms to add separate layer
  var reduceBy = 0;
  $(".selectable-reform.selected").each(function() {
    reduceBy -= $(this).attr("data-reduce-by");
  });
  reduceBy = parseInt(Math.abs(reduceBy));
  if (reduceBy > 0) {
    if ($(".dots .reduce-by").length <= 0) {
      $(".dots").append('<div class="reduce-by"></div>');
    }

    if (reduceBy <= col) {
      $(".reduce-by").css("width", dotWidth + "px");
      $(".reduce-by").css("height", reduceBy * dotWidth + "px");
    } else {
      var row = Math.floor($(".dots").height() / dotWidth),
        totalCols = Math.ceil(reduceBy / row);

      $(".reduce-by").css("width", totalCols * dotWidth + "px");

      var reduceByFullHeight = $(".reduce-by")
        .parents(".dots")
        .height();

      $(".reduce-by").css("height", reduceByFullHeight);
      stateDotsSticky.update();
    }
  } else {
    $(".reduce-by").css("width", 0);
    //$(".dots .reduce-by").remove();
    stateDotsSticky.update();
  }

  if ($(".home").length) {
    if ($(".dots").height() <= $(window).height()) {
      $(".section-wrap").height($(window).height());
    } else {
      $(".section-wrap").height($(".dots").height());
    }
  }

  if ($(".section--text + .section--color-change").length) {
    var prevSection = $(".section--text + .section--color-change").prev(
        ".section"
      ),
      offset = prevSection.offset().top + prevSection.height() + 30;

    $(".section--color-change").css({
      top: offset
    });
  }

  updateScrollPosition();

  stateDotsSticky.update();
  //stateTitleSticky.update();
};

var updateScrollPosition = function() {
  var scroll = $(window).scrollTop(),
    height = $(document).height() - $(window).height();
  if ($(window).width() <= 767) {
    height = $(document).height() - $(window).height();
  }
  var topOffset = (scroll / height) * 100,
    dotCount = $(".dots").attr("data-dot-count");

  // calculate rough estimate of dot #
  var estimatedScroll = (dotCount * topOffset) / 100;

  if (estimatedScroll <= 0) {
    estimatedScroll = 1;
  }

  $(".scroll-position").text(
    Math.floor(estimatedScroll)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );

  // if ($(".scroll-position").text() == "0") {
  //   $(".scroll-position").hide();
  // } else {
  //   $(".scroll-position").show();
  // }
  function scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
    return (
      ((maxAllowed - minAllowed) * (unscaledNum - min)) / (max - min) +
      minAllowed
    );
  }

  topOffset = (scroll / height) * 100;
  function convertRange(value, r1, r2) {
    return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[0] - r1[1]) + r2[1];
  }

  var addon = convertRange(topOffset, [0, 100], [0, 95]);
  $(".scroll-position").css({
    top: "calc(" + topOffset + "% + " + addon + "px)"
  });
};

var initStatePage = function() {
  $(".addable-dot-count").each(function() {
    var originalCount = parseInt($(this).attr("data-original-count")),
      reduceBy = originalCount;
    $(".selectable-reform").each(function() {
      var amt = $(this).attr("data-reduce-by");
      reduceBy += parseInt(amt);
    });
    $(this).text(reduceBy.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  });

  $(".reducable-dollar-amount").each(function() {
    reduceBy = originalAmount;
    $(".selectable-reform").each(function() {
      var amt = $(this).attr("data-reduce-dollars-by");
      reduceBy += parseInt(amt);
    });
    $(this).text(
      "$" + reduceBy.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  });

  var updateReformTotals = function() {
    $(".reducable-dot-count").each(function() {
      var originalCount = $(this).attr("data-original-count"),
        reduceBy = originalCount;
      $(".selectable-reform.selected").each(function() {
        reduceBy -= $(this).attr("data-reduce-by");
      });
      if (originalCount == reduceBy) {
        $(this).text("");
      } else {
        $(this).text("-"+(originalCount - reduceBy).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      }
    });

    updateDots();
  };

  $(".selectable-reform").each(function() {
    var thisReform = $(this);
    var reformMonitor = scrollMonitor.create(thisReform[0], 250);
    reformMonitor.fullyEnterViewport(function() {
      thisReform.prevAll(".selectable-reform").addClass("selected");
      thisReform.addClass("selected");
      updateReformTotals();
    });
    reformMonitor.partiallyExitViewport(function() {
      if (reformMonitor.isBelowViewport) {
        thisReform.removeClass("selected");
      }
      updateReformTotals();
    });
  });

  $(".action-link a").on("touchstart click", function(e) {
    e.preventDefault();
    $("html, body").animate(
      {
        scrollTop: $(".reforms").offset().top - $(".primary-nav").height()
      },
      1000
    );
  });

  // sticky stuff
  if ($("body").hasClass("status--published")) {
    var titleWrap = $(".nav-will-stick"),
      titleSpacer = $(".title-spacer"),
      titleSpacerMonitor = scrollMonitor.create(titleSpacer[0], 60);

    var actionLinkSpacer = $(".action-link"),
      actionLinkMonitor = scrollMonitor.create(actionLinkSpacer[0], -60);

    titleSpacerMonitor.partiallyExitViewport(function() {
      titleWrap.toggleClass("fixed", this.isAboveViewport);
    });

    actionLinkMonitor.enterViewport(function() {
      titleWrap.removeClass("fixed", this.isAboveViewport);
    });

    var reformsWrap = $(".reforms-wrap");
    var reformsWrapMonitor = scrollMonitor.create(reformsWrap[0]);
    var ctaWrap = $(".last-slide");
    var ctaWrapMonitor = scrollMonitor.create(ctaWrap[0]);
    var ctaWrapMonitorNav = scrollMonitor.create(ctaWrap[0], -150);

    reformsWrapMonitor.lock();

    reformsWrapMonitor.partiallyExitViewport(function() {
      if (
        $(reformsWrap).height() < $(".dots").height() &&
        $(window).width() >= 768
      ) {
        $(reformsWrap).toggleClass("fixed", this.isAboveViewport);
      }
    });

    ctaWrapMonitorNav.fullyEnterViewport(function() {
      var topOffset = $(".site-header").offset().top;
      $(".site-header")
        .css({
          position: "absolute"
        })
        .addClass("with-bg");
      $(
        ".site-header .site-logo, .site-header .primary-nav, .site-header .bg, .site-header .mobile-progress"
      ).css({
        top: topOffset,
        position: "absolute"
      });
      titleWrap.removeClass("fixed").addClass("absolute");
      $(".sticky-parent")
        .removeClass("fixed")
        .addClass("absolute");
    });

    ctaWrapMonitorNav.partiallyExitViewport(function() {
      if (!this.isAboveViewport) {
        $(".site-header")
          .css({
            position: "fixed"
          })
          .removeClass("with-bg");
        $(
          ".site-header .site-logo, .site-header .primary-nav, .site-header .bg, .site-header .mobile-progress"
        ).css({
          top: 0,
          position: "fixed"
        });
        titleWrap.removeClass("absolute").addClass("fixed");
        $(".sticky-parent")
          .removeClass("absolute")
          .addClass("fixed");
      }
    });

    ctaWrapMonitor.enterViewport(function() {
      if (
        $(reformsWrap).height() < $(".dots").height() &&
        $(window).width() >= 768
      ) {
        $(reformsWrap)
          .removeClass("fixed")
          .addClass("absolute");
      }
    });

    ctaWrapMonitor.exitViewport(function() {
      if (
        $(reformsWrap).height() < $(".dots").height() &&
        $(window).width() >= 768
      ) {
        $(reformsWrap)
          .removeClass("absolute")
          .addClass("fixed");
      }
    });

    scrollMonitor.update();
  }
};

var initForms = function() {
  var overrideFields = [
    "ms",
    "initms",
    "ms_aff",
    "initms_aff",
    "ms_chan",
    "initms_chan"
  ];

  $(overrideFields).each(function() {
    // check for url param
    if (getParameterByName(this) != "") {
      // set cookie
      Cookies.set(this, getParameterByName(this));
    }
  });

  // update forms
  function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  $("form.sb-form").each(function(idx, form) {
    $(overrideFields).each(function() {
      if (getParameterByName(this) != "" || Cookies.get(this)) {
        var newVal = getParameterByName(this) || Cookies.get(this);
        $('[name="' + this + '"]').val(newVal);
      }
    });

    $(form).on("submit", function(e) {
      function displayEmailSignupErrorMessage() {
        $(form)
          .find(".status")
          .html(
            "<p>There was an error with your submission. Please try again.</p>"
          );
      }

      var url = $(this).attr("action");
      e.preventDefault();

      // if phone field is filled in, adjust other fields
      if (
        $(form)
          .find('[name="sbp_phone"]')
          .val()
      ) {
        $('input[name="field_phone_type"]').val("Unknown");
        $('input[name="sms_opt_in"]').val("1");
        $('input[name="mobile_autodial_opt_in"]').val("1");
      } else {
        $('input[name="field_phone_type"]').val("");
        $('input[name="sms_opt_in"]').val("0");
        $('input[name="mobile_autodial_opt_in"]').val("0");
      }


      $(form).find('[name="submission_date_time"]').val(Math.round(Date.now() / 1000));

      $.ajax({
        type: "POST",
        url: url,
        crossDomain: true,
        data: new FormData(this),
        processData: false,
        contentType: false,
        headers: {
          Accept: "application/json"
        },
        success: function(data) {
          if (Array.isArray(data) && data[0].status === "Submission successful") {
            $(form)
              .find(".status")
              .html("<p>Thank you for signing up.</p>");
          } else {
            displayEmailSignupErrorMessage();
          }
        },
        error: displayEmailSignupErrorMessage
      });
    });
  });
};
